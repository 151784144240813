import React from 'react'
import Link from 'gatsby-link'

const Menu = ({ toggle }) => {
  return (
    <ul>
      <li>
        <Link onClick={toggle ? toggle : ''} to="/#about">
          About
        </Link>
      </li>
      <li>
        <Link to="/work">Work</Link>
      </li>
      <li>
        <Link to="/resume">Resume</Link>
      </li>
      <li>
        <a target="_blank" href="mailto:robertwerner28@gmail.com">
          <i className="fa fa-envelope" />
        </a>
      </li>
      <li>
        <a target="_blank" href="https://www.linkedin.com/in/rob-w-0b90b1100/">
          <i className="fa fa-linkedin" />
        </a>
      </li>
      <li>
        <a target="_blank" href="https://twitter.com/robwerner28">
          <i className="fa fa-twitter" />
        </a>
      </li>
    </ul>
  )
}

export default Menu
