import React, { Component } from 'react'
import styled from 'styled-components'

const FooterWrap = styled.footer`
  background-color: #2a2a2a;
  padding: 20px 0px;

  .inner-wrap {
    p {
      color: #fff;
      text-align: center;
      margin: 0px;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-size: 11px;
    }
  }
`

export default class Footer extends Component {
  render() {
    return (
      <FooterWrap>
        <div className="inner-wrap">
          <p>© 2018 Rob Werner. All rights reserved.</p>
        </div>
      </FooterWrap>
    )
  }
}
