import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { TransitionMixin, media } from '../helpers'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BigTitleWrap = styled.div`
  margin-bottom: 20px;
  text-align: center;
  ${media.large`text-align: left;`};
  .inner-wrap {
    background: #000;
    display: inline-block;
    padding: 10px 20px;
    h1 {
      color: #fff;
      margin-bottom: 0px;
    }
  }
`

const SplitGrid = styled.div`
  ${media.large`display: grid; grid-template-columns: 30% 70%;`};
  p {
    font-size: 15px;
    line-height: 27px;
  }

  .sidebar {
    position: relative;

    > .inner-wrap {
      background: #000;
      overflow-y: scroll;

      ${media.large`height: 100vh; width: 30%;position: fixed;`};
    }

    .prof-section {
      padding-top: 40px;
      text-align: center;
      .prof-wrap {
        position: relative;
        margin: 0 auto;
        max-width: 150px;
        ${media.large`max-width: 300px;`};
        ${media.xl`max-width: 250px;`};
      }

      .title {
        padding: 20px 0px;

        h1 {
          color: #fff;
          font-family: 'Lato', sans-serif;
          letter-spacing: 2px;

          span {
            font-size: 0.4em;
            display: block;
            letter-spacing: normal;
            color: #777;
          }
        }
      }

      .contact-box {
        .inner-wrap {
          border: 1px solid #fff;
          max-width: 300px;
          margin: 0 auto;

          ul {
            list-style: none;
            padding: 20px 0px;
            margin: 0px;

            li {
              display: inline-block;
              margin: 0px 10px;

              a {
                color: #fff;
                font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
                  Helvetica Neue, sans-serif;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                text-decoration: none;
                font-size: 14px;
                ${TransitionMixin('.25s')};
                &:hover,
                &:active,
                &:focus {
                  text-decoration: none;
                }

                &:hover {
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }

      .toolkit {
        margin-top: 40px;
        ${media.xl`margin-top: 90px;`};
        .inner-wrap {
          max-width: 300px;
          margin: 0 auto;
        }
        h2 {
          color: #fff;
          font-family: 'Lato', sans-serif;
          text-align: center;
          font-size: 21px;
          ${media.large`text-align: left;`};
        }

        ul {
          padding: 0px;
          margin: 0px;
          list-style: none;
          text-align: center;
          ${media.large`text-align: left;`};
          li {
            color: #777;
            display: inline-block;
            margin-right: 20px;
            border: 1px solid #fff;
            padding: 5px 10px;
            font-size: 13px;
            text-transform: uppercase;
            font-family: 'Lato', sans-serif;
            letter-spacing: 1px;
            border-radius: 7px;
          }
        }
      }
    }
  }

  .primary-content {
    max-width: 800px;
    margin: 50px auto 0px;
    padding: 0px 20px;

    ${media.large`padding: 0px;`};

    h1 {
      font-size: 25px;
    }

    .summary,
    .experience {
      margin-bottom: 40px;
      ${media.xl`margin-bottom: 70px;`};
    }

    .experience {
      .job-wrap {
        .des-wrap {
          max-width: 570px;
          margin: 0 auto;
        }
        .job {
          .job-header {
            border-bottom: 1px solid #efefef;
            padding: 20px 0px;

            ${media.large`display: grid; grid-template-columns: 19% 35% 25% 20%; grid-gap: 3%;`};
            .job-image {
              max-width: 85px;
              width: 100%;
              margin: 0 auto;
            }
            .job-title {
              align-self: center;
              text-align: center;
              ${media.large`text-align: left;`};
              h3 {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                margin: 0px;
                font-size: 20px;
                letter-spacing: 1px;
                color: #000;
                line-height: 25px;
                span {
                  font-size: 17px;
                  font-weight: 300;
                  color: #777;
                  display: block;
                  letter-spacing: 1px;
                  margin: 5px 0px;
                  line-height: 25px;
                }
              }
            }
            .job-date {
              margin: 5px 0px;
            }

            .job-date {
              align-self: center;
              text-align: center;
              ${media.large`text-align: left;`};
              h3 {
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                font-size: 17px;
                margin: 0px;
                color: #777;
                line-height: 25px;
              }
            }
          }
          .job-body {
            ${TransitionMixin('.25s')};
            opacity: 0;
            border-bottom: 1px solid #efefef;
            max-height: 0;

            ul {
              padding: 0px;
              margin: 0px;
              li {
                font-size: 15px;
                margin-bottom: 10px;
              }
            }

            &.active {
              opacity: 1;
              max-height: 1000px;
              padding: 30px 0px 20px;
            }
          }

          .no-dot {
            list-style: none;
          }

          .job-toggle {
            align-self: center;
            text-align: center;
            a {
              font-size: 30px;
              color: #000;

              i {
                font-size: 30px;
                ${TransitionMixin('.25s')};
                &.active {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }

    .education {
      padding-bottom: 70px;
      .school-wrap {
        padding: 20px 0px 20px;
        .school {
          .school-header {
            text-align: center;
            ${media.large`text-align: left; display: grid; grid-template-columns: 19% 60% 30%;`};
            .school-image {
              max-width: 85px;
              width: 100%;
              margin: 0 auto;

              &.marked {
                border: 1px solid #efefef;
              }
            }

            .school-title {
              align-self: center;
              margin: 5px 0px;
              h3 {
                font-size: 20px;
                font-family: 'Roboto', sans-serif;
                letter-spacing: 1px;
                color: #000;
                margin: 0px;
                font-weight: 400;

                span {
                  color: #777;
                  font-size: 16px;
                  display: block;
                  font-weight: 300;
                  letter-spacing: 1px;
                  margin: 5px 0px;
                }
              }
            }

            .school-date {
              align-self: center;
              text-align: center;
              margin: 5px 0px;
              h3 {
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                font-size: 17px;
                margin: 0px;
                color: #777;
              }
            }
          }
        }
      }
    }
  }
`

export default class ResumePage extends Component {
  state = {
    activeJob: -1,
  }

  componentDidMount() {
    document.querySelector('#header-wrap').style.display = 'none'
  }

  toggleJobDescription = e => {
    e.preventDefault()
    console.log('diddy', e.target.parentElement.dataset.job)

    if (e.target.parentElement.classList.contains('active')) {
      this.setState({
        activeJob: -1,
      })
    } else {
      this.setState({
        activeJob: [e.target.parentElement.dataset.job],
      })
    }
  }

  render() {
    const { data } = this.props
    console.log('data', data)
    const { acf } = data.wordpressPage
    function createMarkup() {
      return { __html: data.wordpressPage.content }
    }

    return (
      <div>
        <SEO
          title="Resume | Rob J. Werner"
          description="I’m a sports journalist from San Francisco, currently studying at the Walter Cronkite School of Journalism and Mass Communication at Arizona State University."
        />
        <Layout>
          <SplitGrid>
            <div className="sidebar">
              <div className="inner-wrap">
                <div className="prof-section">
                  <div className="prof-wrap">
                    <Img
                      fluid={data.prof.childImageSharp.fluid}
                      imgStyle={{ borderRadius: '5%' }}
                    />
                  </div>
                  <div className="title">
                    <h1>
                      Rob Werner <span>Anchor/Journalist</span>
                    </h1>
                  </div>
                  <div className="contact-box">
                    <div className="inner-wrap">
                      <ul>
                        <li>
                          <Link to="/">Website</Link>
                        </li>
                        <li>
                          <a href="mailto:robertwerner28@gmail.com">Email</a>
                        </li>
                        <li>
                          <a target="_blank" href={acf.resume_link.source_url}>
                            PDF
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="toolkit">
                    <div className="inner-wrap">
                      <div className="title">
                        <h2>Toolkit</h2>

                        <ul>
                          {acf.toolkit.map(tool => {
                            return <li>{tool.tool}</li>
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="primary-content">
              <div className="summary">
                <BigTitleWrap>
                  <div className="inner-wrap">
                    <h1>Summary</h1>
                  </div>
                </BigTitleWrap>
                <div
                  className="summary-wrap"
                  dangerouslySetInnerHTML={createMarkup()}
                />
              </div>
              <div className="experience">
                <BigTitleWrap>
                  <div className="inner-wrap">
                    <h1>Experience</h1>
                  </div>
                </BigTitleWrap>
                {acf.experience.map((job, i) => {
                  function createMarkup() {
                    return { __html: job.job_description }
                  }
                  return (
                    <div className="job-wrap">
                      <div className="job">
                        <div className="job-header">
                          <div className="job-image">
                            {job.company_logo && job.company_logo.localFile ? (
                              <Img
                                fluid={
                                  job.company_logo.localFile.childImageSharp
                                    .fluid
                                }
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="job-title">
                            <h3>
                              {job.company_title} <span>{job.job_title}</span>
                            </h3>
                          </div>

                          <div className="job-date">
                            <h3>{job.duration_of_job}</h3>
                          </div>
                          <div className="job-toggle">
                            <a
                              href="#"
                              data-job={i}
                              onClick={this.toggleJobDescription}
                              className={
                                this.state.activeJob == i ? 'active' : ''
                              }
                            >
                              <i
                                className={
                                  this.state.activeJob == i
                                    ? 'fa fa-angle-down active'
                                    : 'fa fa-angle-down'
                                }
                              />
                            </a>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.activeJob == i
                              ? 'job-body active'
                              : 'job-body'
                          }
                        >
                          <div
                            className="des-wrap"
                            dangerouslySetInnerHTML={createMarkup()}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="education">
                <BigTitleWrap>
                  <div className="inner-wrap">
                    <h1>Education</h1>
                  </div>
                </BigTitleWrap>

                {acf.education.map(school => {
                  console.log(school)
                  return (
                    <div className="school-wrap">
                      <div className="school">
                        <div className="school-header">
                          <div className="school-image">
                            <Img
                              fluid={
                                school.school_logo.localFile.childImageSharp
                                  .fluid
                              }
                            />
                          </div>
                          <div className="school-title">
                            <h3>
                              {school.school_name}{' '}
                              <span>{school.school_degree}</span>
                            </h3>
                          </div>
                          <div className="school-date">
                            <h3>{school.school_duration}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </SplitGrid>
        </Layout>
      </div>
    )
  }
}

export const query = graphql`
  query resumeQuery {
    prof: file(relativePath: { eq: "resume.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wordpressPage(title: { eq: "Resume" }) {
      title
      id
      content
      acf {
        resume_link {
          source_url
        }
        experience {
          company_title
          job_title
          duration_of_job
          job_description
          company_logo {
            id
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 300, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        education {
          school_logo {
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 300, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          school_name
          school_degree
          school_duration
        }
        toolkit {
          tool
        }
      }
    }
  }
`
