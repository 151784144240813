import { Link } from 'gatsby'
import Menu from './menu'
import React from 'react'
import { TransitionMixin, media } from '../helpers'
import styled from 'styled-components'

const HeaderWrap = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #efefef;
  background: #fff;
  ${media.small`background: transparent;`};
  ${TransitionMixin('.25s')};

  &.active {
    background-color: #fff;
    border-color: #efefef;
    > .wrapper {
      .logo-wrap {
        a {
          border-color: #efefef;
          h1 {
            color: #2a2a2a;

            ${media.small`font-size: 30px;`};
          }
        }
      }
      .link-wrap {
        nav.desktop {
          li {
            a {
              color: #2a2a2a;
            }
          }
        }
      }
    }
  }
  > .wrapper {
    display: flex;
    margin: 0 auto;
    .logo-wrap {
      flex: 1;
      align-self: center;
      a {
        text-decoration: none;
        display: inline-block;
        padding: 20px;

        ${media.medium`border-right: 1px solid #fff;padding: 20px 40px;`};
        h1 {
          color: #2a2a2a;
          margin: 0px;
          font-size: 1.25rem;
          ${TransitionMixin('.25s')};
          ${media.small`color: #fff; `};
          ${media.medium`font-size: 2.25rem;`};
        }
      }
    }

    .link-wrap {
      flex: 1;
      text-align: right;
      align-self: center;
      .mobile {
        ${media.small`display: none;`};
      }
      nav.desktop {
        margin-right: 30px;
        display: none;

        ${media.small`display: block;`};
        ul {
          list-style: none;
          padding: 0px;
          margin: 0px;

          li {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 0px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 13px;

            a {
              color: #2a2a2a;
              font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                sans-serif;
              text-decoration: none;
              ${TransitionMixin('.25s')};

              ${media.small`color: #fff;`};

              &:hover,
              &:active {
                opacity: 0.7;
              }

              i {
                font-size: 100px;
                font-size: 21px;
              }
            }
          }
        }
      }
    }
  }
  .mobile-wrap {
    position: absolute;
    top: 71px;
    background: rgba(42, 42, 42, 0.8);
    width: 100%;
    height: 100vh;
    opacity: 0;
    transform: translateY(100%);
    ${TransitionMixin('.25s')};

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    > .wrapper {
      padding: 40px 20px;

      .mobile-menu {
        ul {
          li {
            list-style: none;
            margin-bottom: 15px;
            a {
              color: #fff;
              font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                sans-serif;
              text-decoration: none;
              text-transform: uppercase;
              letter-spacing: 1px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
`

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.header = React.createRef()
  }

  componentDidMount() {
    function headerCheck() {
      const header = document.querySelector('#header-wrap')
      if (window.scrollY > 50) {
        header.classList.add('active')
      } else {
        header.classList.remove('active')
      }
    }

    headerCheck()

    window.addEventListener('scroll', function() {
      headerCheck()
    })
  }

  hamburgerToggle = e => {
    if (document.querySelector('#hamburger').classList.contains('is-active')) {
      document.querySelector('#hamburger').classList.remove('is-active')
      document.querySelector('#mm').classList.remove('active')
    } else {
      document.querySelector('#hamburger').classList.add('is-active')
      document.querySelector('#mm').classList.add('active')
    }
  }

  render() {
    return (
      <HeaderWrap id="header-wrap">
        <div className="wrapper">
          <div className="logo-wrap">
            <Link to="/">
              <h1>Rob Werner</h1>
            </Link>
          </div>
          <div className="link-wrap">
            <div className="mobile">
              <button
                className="hamburger hamburger--slider"
                id="hamburger"
                onClick={this.hamburgerToggle}
              >
                <div className="hamburger-box">
                  <div className="hamburger-inner" />
                </div>
              </button>
            </div>

            <nav className="desktop">
              <Menu />
            </nav>
          </div>
          <div className="mobile-wrap" id="mm">
            <div className="wrapper">
              <div className="mobile-menu">
                <Menu toggle={this.hamburgerToggle} />
              </div>
            </div>
          </div>
        </div>
      </HeaderWrap>
    )
  }
}

export default Header
